(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

window.sitelang = {
    'index': require('./en/index.js'),
    'product': require('./en/product.js'),
    'basket': require('./en/basket.js'),
    'software': require('./en/software.js'),
    'technic': require('./en/technic.js')
};

},{"./en/basket.js":2,"./en/index.js":3,"./en/product.js":4,"./en/software.js":5,"./en/technic.js":6}],2:[function(require,module,exports){
'use strict';

module.exports = {
    'order_details': 'Order Details',
    'my_pending_orders': 'My Pending Orders',
    'approved_orders': 'Approved Orders',
    'unapproved_orders': 'Unapproved Orders',
    'show_basket': 'Show Basket',
    'all_orders': 'All Orders',
    'basket_description': 'You can see the products that you add to the basket below',
    'order_list': 'Order List',
    'process': 'Process',
    'product_photo': 'Product Photo',
    'product': 'Product',
    'piece': 'Piece',
    'unit_price': 'Unit Price',
    'total_net': 'Total Net',
    'total_price': 'Total Price',
    'go_product': 'Go to the Product',
    'complete_order': 'Complete the order',
    'continue_shopping': 'Continue shopping',
    'clear_basket': 'Clear the basket',
    'campaign': 'Campaign',
    'delete_product_message': 'Do you want to delete the selected product?',
    'clear_message': 'Do you want to clear the order list?',
    'confirm_message': 'Do you want to confirm the order list?',
    'add_basket': 'Add to Basket',
    'advice_price': 'Recommended price : ',

    'basket_adding_message': 'We are adding product to basket, Please wait...',
    'product_added_basket': 'Successfully added to basket',
    'product_added_basket_info_message': 'In order to complete your order you have to submit.',
    'product_adding_failed': 'Can not add product',
    'we_are_sorry': 'We are sorry.',
    'enter_piece': 'Enter piece',
    'orders_saving': 'Please wait. Saving order...',
    'orders_saving_failed': 'An error occured while saving your order.',
    'orders_saved': 'Order saved',
    'good_day': 'Have good day!',
    'cleaning_basket': 'Please wait, cleaning your basket',
    'cleaned_basket': 'Basket is now empty',
    'cleaning_failed_basket': 'Can not clean your basket',
    'deleting_product': 'Please wait, deleting product from basket',
    'deleted_product': 'Product removed from basket',
    'deleting_failed_product': 'Can not remove product',
    'do_you_want_continue': 'Do you want to continue?',
    'valid_email': 'Please enter a valid email. ',
    'email_saved': 'Your email is saved.',
    'please_wait': 'Please wait'
};

},{}],3:[function(require,module,exports){
'use strict';

module.exports = {
    'homepage_label': 'Homepage',
    'categories_label': 'Categories',
    'login_label': 'Login',
    'logout_label': 'Logout',
    'admin_label': 'Admin',
    'account_settings_label': 'Account Settings',
    'my_orders_label': 'My Orders',
    'technical_service_label': 'Technical Service',
    'lang_label': 'Language',
    'order_basket_label': 'Order Basket',
    'newsletter_label': 'In order to join our mailing list please enter your e-mail address',
    'contact_label': 'Contact',
    'contact_us_label': 'Contact Us',
    'human_resources_label': 'Human Resources',
    'site_map_label': 'Site Map',
    'customer_service_label': 'Customer Service',
    'account_label': 'Account',
    'my_account_label': 'My Account',
    'order_history_label': 'Order History',
    'new_order_label': 'New Order',
    'product_label': 'Product',
    'catalog_label': 'Catalog',
    'product_group_label': 'Product Group',
    'price_list_label': 'Price List',
    'campaign_list_label': 'Campaign List',
    'social_media_label': 'Social Media',
    'copyright_label': 'All rights reserved',
    'basket_label': 'Basket',
    'alert_success': 'Continue Task',
    'alert_cancel': 'Cancel',
    'file_not_found': 'File not found',
    'click': 'Click',
    'here': 'here',
    'see_price_list_part1': 'Click',
    'see_price_list_part2': 'to see the price list',
    'see_campaign_list_part1': 'Click',
    'see_campaign_list_part2': 'to see the campaign products',
    'campaign': 'Campaign',
    'next': 'Next',
    'prev': 'Prev',
    'news': 'News',
    'new': 'New',
    'search': 'Search'
};

},{}],4:[function(require,module,exports){
'use strict';

module.exports = {
    'features_label': 'Features',
    'software_label': 'Software',
    'updater_label': 'Updater',
    'application_label': 'Application',
    'channel_list_label': 'Channel List',
    'manual_label': 'Manual',
    'campaign_products_label': 'Campaign Products',
    'file_title': 'File Title',
    'file_name': 'File Name',
    'file_type': 'File Type',
    'download': 'Download',
    'could_not_find_product': 'Couldn\'t find any product in this category',
    'date': 'Date'
};

},{}],5:[function(require,module,exports){
'use strict';

module.exports = {
    'software': 'Software',
    'product_name': 'Product Name',
    'category_name': 'Category Name',
    'functions': 'Functions',
    'product_page': 'Product Page',
    'list_file': 'List File',
    'next': 'Next',
    'prev': 'Prev',
    'copy_link': 'Copy Link'
};

},{}],6:[function(require,module,exports){
'use strict';

module.exports = {
    'technic': 'Technical Service',
    'technic_list': 'Technic Service',
    'repair_list': 'Repair List',
    'repair_list_detail': 'Repair Detail List',
    'technic_service_status': 'Technical Service Status',
    'expected': 'Expected',
    'in_service': 'In Service',
    'past': 'Past',
    'new_repair': 'New Repair',
    'expected_list': 'Expected List',
    'past_list': 'Past List',
    'in_service_list': 'In Service List',
    'dealer': 'Dealer',
    'repair_number': 'Repair Number',
    'date': 'Date',
    'view': 'View',
    'required': 'Required',
    'serial_number': 'Serial Number',
    'serial_number_placeholder': 'Enter Serial Number',
    'accessory': 'Accessory',
    'accessory_placeholder': 'Enter Accessory',
    'product': 'Product',
    'problem': 'Problem',
    'problem_placeholder': 'Enter Problem',
    'add': 'Add',
    'clear': 'Clear',
    'settings': 'Settings',
    'delete': 'Delete',
    'all': 'All',
    'product_list': 'Product List',
    'send': 'Send',
    'cancel': 'Cancel',
    'status': 'Status',
    'repair_message': 'Repair Message',
    'description': 'Description'
};

},{}]},{},[1]);
